(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');
    MyHippoCommonDirectives.directive('mhAddSubOrganization', mhAddSubOrganization);

    mhAddSubOrganization.$inject = ['toaster'];
    function mhAddSubOrganization (toaster) {
        return {
            scope: { selectedOrgs: '<', producerId: '<' },
            restrict: 'E',
            template: `<button class="cBtnRound" ng-click="showAddSubOrgModal()">Add Sub Organization</button>`,
            controller: function ($scope, $mdDialog, APIService) {
                $scope.showAddSubOrgModal = function () {
                    $mdDialog.show({
                        controller: addSubOrgController,
                        templateUrl: 'views/templates/addSubOrgModal.html',
                        parent: angular.element(document.body),
                        locals: {
                            subOrgs: $scope.subOrgs,
                            selectedOrgs: $scope.selectedOrgs,
                            producerId: $scope.producerId
                        }
                    });
                };

                function addSubOrgController ($scope, $mdDialog, APIService, toaster, selectedOrgs, producerId) {
                    $scope.selectedOrgs = _.cloneDeep(selectedOrgs || []);
                    const originallySelectedOrgs = _.cloneDeep($scope.selectedOrgs);
                    $scope.errorMessage = '';
                    APIService.findOrganizations().then(({ organizations }) => {
                        $scope.subOrgs = organizations.filter(o => o.parent_id !== null);
                    });
                    $scope.maxOrgLength = 20;
                    $scope.getOrgNameById = (id, fullName = false) => {
                        const foundOrg = _.find($scope.subOrgs, o => o.id === id);
                        if (foundOrg) {
                            if (foundOrg.name.length > $scope.maxOrgLength && !fullName) {
                                return  foundOrg.name.substr(0, 18) + '...';
                            }
                            return foundOrg.name;
                        }
                        return null;
                    };

                    // If not nested inside another object
                    // Angular hides this value from the controller somewhere
                    $scope.search = { query: '' };
                    $scope.searchByName = ({ name }) => {
                        const { query } = $scope.search;
                        return name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
                    };


                    $scope.filterOrgs = () => {
                        $scope.query = $scope.search.query;
                    };
                    $scope.isAlreadySelected = ({ id }) => !!$scope.selectedOrgs.find(o => o.org_id === id);
                    $scope.selectOrganization = (org) => {
                        if ($scope.selectedOrgs.length < 10) {
                            const { name, id } = org;
                            $scope.errorMessage = '';
                            $scope.selectedOrgs.push({ org_id: id });
                        } else {
                            $scope.errorMessage = 'Max number of suborgs reached';
                        }
                    };
                    $scope.removeSelected = ({ org_id }) => {
                        const removalIndex = $scope.selectedOrgs.findIndex(o => org_id === o.org_id);
                        $scope.selectedOrgs.splice(removalIndex, 1);
                        $scope.errorMessage = '';
                    };

                    $scope.save = () => {
                        const orgsToDelete = originallySelectedOrgs.filter(org => {
                            return !_.find($scope.selectedOrgs, (o) => org.org_id === o.org_id);
                        });

                        const update = $scope.selectedOrgs.reduce((update, { org_id }, index) => {
                            const isNew = !_.find(originallySelectedOrgs, o => org_id === o.org_id);
                            if (isNew) {
                                update[`new${index}`] = { org_id };
                            }
                            return update;
                        }, {});
                        orgsToDelete.forEach(({ id }) => { update[id] = null; });

                        const updateFormat = { data: { sub_organizations: update } };

                        // Get producer id
                        APIService.updateProducer(producerId, updateFormat).then((producer) => {
                            // Reset
                            const updateSelectedOrgs = _.get(producer, 'data.sub_organizations');
                            selectedOrgs.splice(0, selectedOrgs.length, ...updateSelectedOrgs);
                            $mdDialog.hide();
                        }).catch(err => {
                            $scope.errorMessage = JSON.stringify(err);
                        });
                    };
                    $scope.cancel = () => $mdDialog.cancel();
                }
            }
        };
    }
})(window.angular, window._);
